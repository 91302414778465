import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj12 = () => {
  return (
    <Layout>
      <SEO
        title="Area Calculation-Don't Measure"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-12">
          <div className="py-1-heading">
            <h1>Area Calculation-Don't Measure</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Area%20Calculation-Don_t%20Measure"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              We have a image of India, in which Punjab has diffrent colour,
              <br />
              and the rest of part with some another color.
              <br />
              <br />
              All we have to do is to find the estimated area of punjab.
              <br />
              <br />
              For this, check RGB values for both color .<br />
              An image consists of matrix of pixels. <br />
              A pixel is basically a color.
              <br />
              A color is defined by 3 attributes:-
              <br />
              Amount of red, Amount of blue, Amount of green.
              <br />
              <br />
              Now, put random dots on map, some will fall in punjab others will
              outside it.
              <br />
              That's it, further is just some calculations needed.
              <br />
              <br /> Oh! it's almost equal to area of punjab.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>PIL</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj12
